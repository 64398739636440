import React, { useState } from "react"
import styled from "styled-components"

import NavLinks from "./links"
import NavButton from "./button"

const NavMain = styled.nav`
  margin: 0;
  padding: 0;
  display: ${props => props.isToggled ? 'none' : 'block'};
`

const mql = null;
if (typeof window !== `undefined`) {
  const mql = window.matchMedia('(min-width: 600px)');
  const screenTest = (e) => {
    if (e.matches)
      console.log(`resolution-above-min: ${e}`);
  }
  mql.addListener(screenTest);
}

const Nav = ({ links }) => {
  const [isMinWidth] = useState(mql.matches);
  const [isToggled, setToggle] = useState(true);

  if (isMinWidth) {
    return (
      <NavLinks props={links}/>
    )
  } else {
    return (
      <>
        <NavMain isToggled={isToggled}>
          <NavLinks props={links}/>
        </NavMain>
        <NavButton setToggle={setToggle} isToggled={isToggled} />
      </>
    )
  }
}

export default Nav
