import { useStaticQuery, graphql } from 'gatsby'

const Query = () => {
  const query = useStaticQuery(graphql`
      query {
        msPartnerLogo: file(relativePath: { eq: "ms-partner-logo.jpg" }) {
          ...fixedFooterImage
        }
      }
    `)

  return query
}

export default Query

export const fixedFooterImage = graphql`
fragment fixedFooterImage on File {
  childImageSharp {
    fixed(width: 174, height: 54) {
      ...GatsbyImageSharpFixed
    }
  }
}
`;
