import React from "react"
import styled from "styled-components"
import Img from 'gatsby-image'

import ExtLink from "../base/extlink"

const Footer = styled.footer`
  display: grid;
  margin: 0;
  padding-top: 5rem;
`

const FooterImage = styled(Img)`
  grid-column: 1;
  grid-row: 2;
  margin-top: 1em;
  margin-bottom: 2em;
  border: 0.1em solid black;
  @media (min-width: 600px) {
    margin: 0;
  }
`

const FooterListContainer = styled.div`
  grid-column: 1;
`

const FooterList = styled.ul`
  list-style-type: none;
  margin: 0;
  display: table;
  padding: 0;
  overflow: hidden;
`

const FooterListItem = styled.li`
  margin-bottom: 1rem;
  font-size: 1em;
  text-transform: capitalize;
  @media (min-width: 600px) {
  }
`

const Main = ({ companyTitle, query, filename }) => {
  const links = [
    {uri: `https://azure.microsoft.com/en-us/pricing/calculator/`, title: `Azure Pricing Calculator`},
    {uri: `https://status.azure.com/en-us/status`, title: `Azure Status Dashboard`},
    {uri: `https://docs.microsoft.com/en-us/microsoft-365/compliance/offering-home?view=o365-worldwide`, title: `Microsoft Compliance Offerings`},
  ];
  return (
    <Footer>
      <FooterListContainer>
        <FooterList>
        {links.map( (link, index) => 
          <FooterListItem key={`footer_link_${index}`}>
            <ExtLink props={link} />
          </FooterListItem>
        )}
        </FooterList>
      </FooterListContainer>
      <FooterImage as={Img} fixed={query()[filename].childImageSharp.fixed} />
      <div style={{ gridColumn: `1`, marginTop: `2em` }} >
        © {new Date().getFullYear()} { companyTitle }
        <p style={{ margin: `0`}}>
          The logo design and { companyTitle } are registered trademarks of { companyTitle }
        </p>
      </div>
    </Footer>
  )
}

export default Main
