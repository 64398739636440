import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const BaseLink = styled(Link)`
  color: hsla(0, 0%, 0%, 0.8);
  text-decoration: none;
  :hover {
    color: #ff4f00;
    text-decoration: underline;
  }
`

const Element = ({ props }) => {
  return (
    <BaseLink as={Link} to={`${props.uri}`}>{`${props.title}`}</BaseLink>
  )
}

export default Element
