import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Header from "./header"
import footerImages from "../components/images/footer"
import Footer from "./footer/main"
import pageData from "../data/index.yaml"
import "./reset.css"
import "./layout.css"

const Frame = styled.div`
  display: grid;
  margin: 0 auto;
  max-width: 960;
  padding: 0 1.0875rem 1.45rem;
  padding-left: 2rem;
  padding-right: 2rem;
  @media (min-width: 600px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          companyTitle
        }
      }
    }
  `)

  const navlinks = pageData.cards.map( (card) => card.link);

  return (
    <>
      <Frame>
        <Header style={{ gridRow: `1` }} siteTitle={data.site.siteMetadata.title} links={navlinks} />
        <main style={{ gridRow: `2` }}>{children}</main>
        <Footer companyTitle={data.site.siteMetadata.companyTitle} query={footerImages} filename="msPartnerLogo" />
      </Frame>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
