import React from "react"
import styled from "styled-components"

const NavButton = styled.button`
  color: hsla(0, 0%, 0%, 0.8);
  background-color: #f8f8ff;
  border-top: 0.175em solid hsla(0, 0%, 0%, 0.8);
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  margin: 0;
  margin-top: 1rem;
  width: 100%;
  padding: 0;
  padding-bottom: 0.6em;
  text-decoration: none;
  :hover {
    color: #ff4f00;
  }
  transition: 1s;
  -moz-transform: ${props => props.isToggled ? 'scale(1, 1)' : 'scale(1, -1)'};
  -webkit-transform: ${props => props.isToggled ? 'scale(1, 1)' : 'scale(1, -1)'};
  -o-transform: ${props => props.isToggled ? 'scale(1, 1)' : 'scale(1, -1)'};
  -ms-transform: ${props => props.isToggled ? 'scale(1, 1)' : 'scale(1, -1)'};
  transform: ${props => props.isToggled ? 'scale(1, 1)' : 'scale(1, -1)'};
`

const Button = ({ setToggle, isToggled }) => {
  return (
    <NavButton isToggled={isToggled}
      onClick={ () => setToggle(!isToggled) }>
      &#x25BE;
   </NavButton>
  )
}

export default Button
