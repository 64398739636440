import React from "react"
import styled from "styled-components"

const ExtLink = styled.a`
  color: hsla(0, 0%, 0%, 0.8);
  text-decoration: none;
  :hover {
    color: #ff4f00;
    text-decoration: underline;
  }
`

const Element = ({ props }) => (
  <ExtLink href={`${props.uri}`}>{props.title}</ExtLink>
)

export default Element
