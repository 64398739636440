import React from "react"
import styled from "styled-components"

import BaseLink from "../base/link"

const NavList = styled.ul`
  list-style-type: none;
  margin: 0 auto;
  display: table;
  padding: 0;
  overflow: hidden;
`

const NavListItem = styled.li`
  margin: 2rem;
  text-transform: capitalize;
  @media (min-width: 600px) {
    display: inline-block;
    margin: 1rem;
    letter-spacing: 0.04em;
  }
`

const Links = ({ props }) => {
    const navLinks = props.map( (link, index) =>
      <NavListItem key={`nav_list_item_${index}`}>
        <BaseLink props={{uri: `/${link}`, title: `${link}`}} />
      </NavListItem>
    );
    return ( <NavList>{navLinks}</NavList> )
}

export default Links
