import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Title = styled.h1`
  font-size: 2rem;
  font-weight: 400;
  @media (min-width: 600px) {
    font-size: 2.75rem;
  }
`

const TitleLink = styled(Link)`
  color: hsla(0, 0%, 0%, 0.8);
  text-decoration: none;
`

const SiteTitle = ({ title }) => (
    <Title to={`/`}>
      <TitleLink to={`/`}>{title}</TitleLink>
    </Title>
)

export default SiteTitle
