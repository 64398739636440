import React from "react"
import styled from "styled-components"

import SiteTitle from "./nav/title"
import Nav from "./nav/main"
import GetStartedButton from "./call-to-action/startedButton"

const Header = styled.header`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10em, 1fr));
  justify-content: space-between;
  background-color: #f8f8ff;
  margin-bottom: 3rem;
  padding: 0;
  position: sticky;
  top: 0;
  @media (min-width: 600px) {
    grid-template-columns: unset;
  }
`

const NavList = styled.div`
    grid-row: 2;
    grid-column: 1 / span 3;
    @media (min-width: 600px) {
      grid-row: 1;
      grid-column: 2 / span 3;
      align-self: center;
    }
`

const Section = ({ siteTitle, links }) => {
  return (
  <Header>
    <div style={{
      gridColumn: `1`,
      gridRow: `1`,
    }}>
      <SiteTitle title={siteTitle} />
    </div>
    {
    // <NavList>
    //   <Nav links={links} />
    // </NavList>
    }
    <div style={{
        gridColumnEnd: `auto`,
        gridRow: `1`,
        alignSelf: `center` }}>
      <GetStartedButton />
    </div>
  </Header>
)}

export default Section
