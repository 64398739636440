import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const GetStartedButton = styled(Link)`
  display:inline-block;
  border: 0.1em solid #ff4f00;
  border-radius: 2em;
  margin:0 0.3em 0.3em 0;
  padding: 0.65em 1.15em;
  color: white;
  background-color: #ff4f00;
  text-decoration: none;
  text-align: center;
  letter-spacing: 0.05em;
  transition: all 0.2s;
  :hover {
    color: white;
    text-decoration: underline;
  }
  @media all and (max-width:30em){
    display:block;
    margin:0.4em auto;
  }
`

const Button = () => (
    <GetStartedButton as={ Link } to="/get-started">Get Started!</GetStartedButton>
)

export default Button
